import { useEffect, useState } from 'react';
// @mui
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useParams } from 'react-router-dom';

// components

import Label from 'src/components/Label';
import axios from 'src/utils/axios';

import Page from 'src/components/Page';
import GreenfactoryFooter from 'src/components/greenfactory/FooterStaticPages';
import ThgqpFooter from 'src/components/thgqp/FooterStaticPages';

import StatusTimeLineHorizontal from 'src/components/status/TimelineHorizontal';
import StatusTimeLineVertical from 'src/components/status/TimelineVertical';
import useSettings from 'src/hooks/useSettings';
import LogoOnlyLayout from '../../../layouts/LogoOnlyLayout';
import { fCurrencyEuro } from 'src/utils/formatNumber';

dayjs.locale('de');

const labelMapping = {
  M1: 'E-PKW',
  LXe: 'E-Zweirad',
  N1: 'E-Nutzfahrzeug',
  N2: 'E-Nutzfahrzeug',
  N3: 'E-Nutzfahrzeug',
  M3: 'E-Bus',
};
const subLabelMapping = {
  M1: 'M1',
  LXe: 'L3e, L4e, L5e, L7e',
  N1: 'N1 bis 3,5t',
  N2: 'N2 ab 3,5t bis 12t',
  N3: 'N3 ab 12t',
  M3: 'M3',
};

export default function ProcessStatus() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { thgToken = '' } = useParams();

  const [data, setData] = useState(null);
  const [partnerId, setPartnerId] = useState(null);

  const { themeColors, onChangeColor, changeThemeColor, changeThemePartner } = useSettings();
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (thgToken) {
      fetchData(thgToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thgToken]);

  const fetchData = async (thgToken) => {
    try {
      const endpoint = `/v1/thg/status/${thgToken}`;
      const response = await axios.get(endpoint);
      onChangeColor(response.data.thg.partnerId.toLowerCase(), response.data.colorCode);
      changeThemeColor(response.data.colorCode);
      changeThemePartner(response.data.thg.partnerId);
      setPartnerId(response.data.thg.partnerId);
      setData(response.data);
    } catch (err) {
      console.error(err);
      window.location = '/';
    }
  };

  if (!data) {
    return <></>;
  }

  const salutationMapping = {
    mr: 'Herr',
    mrs: 'Frau',
  };

  if (!data || !data.thg) {
    return (
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
      </Box>
    );
  }

  const payoutPrice = Math.round(data.thg.payoutPriceNet * 1.19);
  const charityAmount = data.thg.charityAmount || 0;
  return (
    <Page
      title="Status der Vermarktung Ihrer THG-Quote"
      sx={{
        '& .MuiFormControl-fullWidth': { marginBottom: 1 },
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <Container
        sx={{
          padding: '0px 32px',
          margin: '0px auto',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          width: '100%',
          maxWidth: { xs: '100%', md: '1400px' },
        }}
        maxWidth={themeStretch ? false : 'xl'}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
          <Box sx={{ minHeight: '75px', margin: '30px -20px' }}>
            <LogoOnlyLayout partnerId={partnerId} />
          </Box>
          <Box sx={{ margin: '50px 0px' }}>
            <Typography variant="h1" sx={{ fontSize: '2rem!important', letterSpacing: '0px' }}>
              Hallo {salutationMapping[data.thg.client.salutation]} {data.thg.client.lastName},
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ marginBottom: '20px' }} variant="h5">
              Hier sehen Sie den aktuellen Status der Vermarktung Ihrer THG-Quote:
            </Typography>{' '}
            <Box sx={{ marginBottom: '40px' }}>
              <Typography>
                <strong>Vorgangsnummer:</strong> {data.thg.processId}
              </Typography>
              {data.thg.status !== 'new' ? (
                <Typography>
                  <strong>Kennzeichen:</strong> {data.thg.vehicle.registrationNumber}
                </Typography>
              ) : (
                <></>
              )}

              <Box>
                <Typography sx={{}}>
                  <strong>{`Auszahlungsbetrag: `}</strong> {fCurrencyEuro(payoutPrice)}
                  {data.thg.client.salesTax && <> (inkl. Mwst.)</>}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{}}>
                  <strong>{`Abrechnungsjahr: `}</strong> {data.thg.year}
                </Typography>
              </Box>
              {data.thg.charityPartner && data.thg.charityAmount && data.thg.charityAmount > 0 ? (
                <>
                  <Typography sx={{}}>
                    <strong>{`Spendenpartner: `}</strong> {data.thg.charityPartner.name}
                  </Typography>
                  <Typography sx={{}}>
                    <strong>{`Spendenbetrag: `}</strong> {fCurrencyEuro(data.thg.charityAmount)}
                  </Typography>
                </>
              ) : (
                <></>
              )}
              <p>
                <strong>Auszahlungstyp:</strong>{' '}
                {data.thg.instantPayout ? 'Sofortauszahlung' : 'Auszahlung nach Bestätigung durch Umweltbundesamt'}
              </p>

              <p>
                {' '}
                <strong>Fahrzeugklasse:</strong>{' '}
                {`${labelMapping[data.thg.vehicle.vehicleClass]} (${subLabelMapping[data.thg.vehicle.vehicleClass]})`}
              </p>
            </Box>
            {isMobileView ? (
              <StatusTimeLineVertical thgProcess={data.thg} />
            ) : (
              <StatusTimeLineHorizontal thgProcess={data.thg} />
            )}
          </Box>{' '}
        </Box>
        {data.platformPartner === 'Greenfactory' ? (
          <GreenfactoryFooter noPadding={true} />
        ) : (
          <ThgqpFooter noPadding={true} />
        )}
      </Container>
    </Page>
  );
}
