import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { PLATFORM_HOST, PLATFORM_HOST_GREENFACTORY } from 'src/config';
import Label from 'src/components/Label';
// components
import SvgIconStyle from 'src/components/SvgIconStyle';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const IS_GREENFACTORY_DOMAIN =
  window.location.hostname === 'greenfactory.msg.group' ||
  window.location.hostname === 'staging.greenfactory.msg.group';

const actualPlatformHost = IS_GREENFACTORY_DOMAIN ? PLATFORM_HOST_GREENFACTORY : PLATFORM_HOST;

//  admin, manager, user
const getSideBarConfig = (countData, user) => {
  const userRole = user.role;
  const userPartner = user.partner;
  const userViewRights = user.viewRights;

  const isAdmin = userRole === 'admin' || userRole === 'supervisor';

  //  ADMIN
  const mainItems = [
    {
      title: 'Dashboard',
      path: '/app/dashboard',
      icon: <Iconify icon={'mdi:view-dashboard'} width={32} height={32} />,
    },
    // {
    //   title: 'THG-Quotenrechner',
    //   path: '/app/thg-calculator/',
    //   icon: <Iconify icon={'ant-design:calculator-ghost'} width={32} height={32} />,
    // },
  ];
  if (isAdmin || userViewRights.includes('fleet_management')) {
    mainItems.push({
      title: 'Fahrzeugbestand',
      path: '/app/fleet/',
      icon: <Iconify icon={'ant-design:file-add-outlined'} width={32} height={32} />,
    });
  }

  if (userRole !== 'validator' && userViewRights.includes('thg_invite')) {
    mainItems.push({
      title: `THG-Einladung`,
      path: `/app/process/invite`,
      icon: <Iconify icon={'mdi:invite'} width={32} height={32} />,
    });
  }

  if (userPartner && userPartner.directSubmit && userViewRights.includes('direct_submit')) {
    mainItems.push({
      title: 'Direkteinreichung',
      path: `https://${actualPlatformHost}/dataform`,
      icon: <Iconify icon={'ant-design:plus-circle-outlined'} width={32} height={32} />,
    });
  }
  if (userRole === 'submissionPartner' || userRole === 'admin') {
    mainItems.push({
      title: 'Einreichungen',
      path: `/app/submissions`,
      info:
        countData['2024'].newSubmissionsCount || countData['2024'].newSubmissionsCount === 0 ? (
          <Label variant="ghost" color="primary">
            {countData['2024'].newSubmissionsCount}
          </Label>
        ) : (
          ''
        ),
      icon: <Iconify icon={'mdi:file-document-box-multiple-outline'} width={32} height={32} />,
    });
  }
  if (isAdmin || (userPartner && userPartner.dataformOptions.showCharity && userViewRights.includes('charity'))) {
    mainItems.push({
      title: 'Spenden',
      path: `/app/charity`,
      icon: <Iconify icon={'mdi:charity-outline'} width={32} height={32} />,
    });
  }

  const processManagementItems = {
    subheader: 'Prozessmanagement',
    items: [
      {
        icon: <Iconify icon={'mdi:shield-edit-outline'} width={32} height={32} />,
        title: 'Vorgänge 2024',
        path: '/process',
        isOpen: true,
        isBold: true,
        isCollapse: true,
        children: [
          {
            title: `Eingang`,
            path: '/app/process/new/2024',
            info:
              countData['2024'].new || countData['2024'].new === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData['2024'].new}
                </Label>
              ) : (
                ''
              ),
          },
          {
            title: `Validiert`,
            path: '/app/process/validated/2024',
            info: (
              <>
                <Label variant="ghost" color="primary">
                  {countData['2024'].validated}
                </Label>
              </>
            ),
          },
          {
            title: `Eingereicht`,
            path: '/app/process/submitted/2024',
            info: (
              <>
                <Label variant="ghost" color="primary">
                  {countData['2024'].submitted}
                </Label>
                {countData['2024'].submittedNotInstant !== 0 ? (
                  <Label variant="ghost" color="warning" sx={{ ml: 1 }}>
                    {countData['2024'].submittedNotInstant}
                  </Label>
                ) : (
                  ''
                )}
              </>
            ),
          },
          {
            title: `Vermarktet`,
            path: '/app/process/sold/2024',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2024'].sold}
              </Label>
            ),
          },
          {
            title: `Warten auf UBA`,
            path: '/app/process/waitingForUba/2024',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2024'].waitingForUba}
              </Label>
            ),
          },
          {
            title: `Bereit zur Auszahlung`,
            path: '/app/process/readyForPayout/2024',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2024'].readyForPayout}
              </Label>
            ),
          },
          {
            title: `Ausgezahlt`,
            path: '/app/process/paidOut/2024',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2024'].paidOut}
              </Label>
            ),
          },
          {
            title: `Abgeschlossen`,
            path: '/app/process/completed/2024',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2024'].completed}
              </Label>
            ),
          },
          {
            title: `Abgelehnt Validierung`,
            path: '/app/process/rejectedValidation/2024',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2024'].rejected_validation}
              </Label>
            ),
          },
          {
            title: `Abgelehnt von UBA`,
            path: '/app/process/rejectedUba/2024',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2024'].rejected_uba}
              </Label>
            ),
          },

          {
            title: `Nicht bestätigt`,
            path: '/app/process/unverified/2024',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2024'].unverified}
              </Label>
            ),
          },
          {
            title: `Archiv`,
            path: '/app/process/archive/2024',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2024'].archive}
              </Label>
            ),
          },
        ],
      },
      {
        icon: <Iconify icon={'mdi:shield-edit-outline'} width={32} height={32} />,
        title: 'Vorgänge 2023',
        path: '/process',
        isOpen: false,
        isBold: true,
        isCollapse: true,
        children: [
          {
            title: `Eingang`,
            path: '/app/process/new/2023',
            info:
              countData['2023'].new || countData['2023'].new === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData['2023'].new}
                </Label>
              ) : (
                ''
              ),
          },
          {
            title: `Validiert`,
            path: '/app/process/validated/2023',
            info: (
              <>
                <Label variant="ghost" color="primary">
                  {countData['2023'].validated}
                </Label>
              </>
            ),
          },
          {
            title: `Eingereicht`,
            path: '/app/process/submitted/2023',
            info: (
              <>
                <Label variant="ghost" color="primary">
                  {countData['2023'].submitted}
                </Label>
                {countData['2023'].submittedNotInstant !== 0 ? (
                  <Label variant="ghost" color="warning" sx={{ ml: 1 }}>
                    {countData['2023'].submittedNotInstant}
                  </Label>
                ) : (
                  ''
                )}
              </>
            ),
          },
          {
            title: `Vermarktet`,
            path: '/app/process/sold/2023',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2023'].sold}
              </Label>
            ),
          },
          {
            title: `Warten auf UBA`,
            path: '/app/process/waitingForUba/2023',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2023'].waitingForUba}
              </Label>
            ),
          },
          {
            title: `Bereit zur Auszahlung`,
            path: '/app/process/readyForPayout/2023',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2023'].readyForPayout}
              </Label>
            ),
          },
          {
            title: `Ausgezahlt`,
            path: '/app/process/paidOut/2023',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2023'].paidOut}
              </Label>
            ),
          },
          {
            title: `Abgeschlossen`,
            path: '/app/process/completed/2023',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2023'].completed}
              </Label>
            ),
          },
          {
            title: `Abgelehnt Validierung`,
            path: '/app/process/rejectedValidation/2023',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2023'].rejected_validation}
              </Label>
            ),
          },
          {
            title: `Abgelehnt von UBA`,
            path: '/app/process/rejectedUba/2023',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2023'].rejected_uba}
              </Label>
            ),
          },

          {
            title: `Nicht bestätigt`,
            path: '/app/process/unverified/2023',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2023'].unverified}
              </Label>
            ),
          },
          {
            title: `Archiv`,
            path: '/app/process/archive/2023',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2023'].archive}
              </Label>
            ),
          },
        ],
      },
      {
        icon: <Iconify icon={'mdi:shield-edit-outline'} width={32} height={32} />,
        title: 'Vorgänge 2022',
        isOpen: false,
        isBold: true,
        path: '/process',
        children: [
          {
            title: `Eingang`,
            path: '/app/process/new/2022',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2022'].new}
              </Label>
            ),
          },
          {
            title: `Validiert`,
            path: '/app/process/validated/2022',
            info: (
              <>
                <Label variant="ghost" color="primary">
                  {countData['2022'].validated}
                </Label>
              </>
            ),
          },
          {
            title: `Eingereicht`,
            path: '/app/process/submitted/2022',
            info: (
              <>
                <Label variant="ghost" color="primary">
                  {countData['2022'].submitted}
                </Label>
                {countData['2022'].submittedNotInstant ||
                  (countData['2022'].submittedNotInstant !== 0 && (
                    <Label variant="ghost" color="warning" sx={{ ml: 1 }}>
                      {countData['2022'].submittedNotInstant}
                    </Label>
                  ))}
              </>
            ),
          },
          {
            title: `Vermarktet`,
            path: '/app/process/sold/2022',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2022'].sold}
              </Label>
            ),
          },
          {
            title: `Warten auf UBA`,
            path: '/app/process/waitingForUba/2022',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2022'].waitingForUba}
              </Label>
            ),
          },
          {
            title: `Bereit zur Auszahlung`,
            path: '/app/process/readyForPayout/2022',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2022'].readyForPayout}
              </Label>
            ),
          },
          {
            title: `Ausgezahlt`,
            path: '/app/process/paidOut/2022',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2022'].paidOut}
              </Label>
            ),
          },
          {
            title: `Abgeschlossen`,
            path: '/app/process/completed/2022',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2022'].completed}
              </Label>
            ),
          },
          {
            title: `Abgelehnt Validierung`,
            path: '/app/process/rejectedValidation/2022',
            info:
              countData['2022'].rejected_validation || countData['2022'].rejected_validation === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData['2022'].rejected_validation}
                </Label>
              ) : (
                ''
              ),
          },
          {
            title: `Abgelehnt von UBA`,
            path: '/app/process/rejectedUba/2022',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2022'].rejected_uba}
              </Label>
            ),
          },

          {
            title: `Nicht bestätigt`,
            path: '/app/process/unverified/2022',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2022'].unverified}
              </Label>
            ),
          },
          {
            title: `Archiv`,
            path: '/app/process/archive/2022',
            info: (
              <Label variant="ghost" color="primary">
                {countData['2022'].archive}
              </Label>
            ),
          },
        ],
      },
    ],
  };
  const paymentManagementItems = {
    subheader: 'Zahlungsmanagement  ',
    items: [
      {
        title: `Auszahlungen`,
        path: `/app/payment/payout`,
        icon: <Iconify icon={'fluent-mdl2:waitlist-confirm'} width={32} height={32} />,
        info:
          countData.payment?.payout || countData.payment?.payout === 0 ? (
            <Label variant="ghost" color="primary">
              {countData.payment.payout}
            </Label>
          ) : (
            ''
          ),
      },
      {
        title: `Rückforderung`,
        path: `/app/payment/claims`,
        icon: <Iconify icon={'fluent-mdl2:waitlist-confirm'} width={32} height={32} />,
        info:
          countData.payment?.claim || countData?.payment?.claim === 0 ? (
            <Label variant="ghost" color="primary">
              {countData.payment.claim}
            </Label>
          ) : (
            ''
          ),
      },
      // {
      //   title: `Rückforderungen`,
      //   path: `/app/payment/claimList`,
      //   icon: <Iconify icon={'fluent-mdl2:waitlist-confirm'} width={32} height={32} />,

      // },
      // {
      //   title: `Überfällig`,
      //   path: `/app/payment/claimUnhandled`,
      //   icon: <Iconify icon={'fluent-mdl2:waitlist-confirm'} width={32} height={32} />,

      // },
      // {
      //   title: `Rückabgewickelt`,
      //   path: `/app/payment/claimCompleted`,
      //   icon: <Iconify icon={'fluent-mdl2:waitlist-confirm'} width={32} height={32} />,

      // },
    ],
  };
  const claimsManagementItems = {
    subheader: 'Claimsmanagement  ',
    items: [
      {
        title: `THG-Prämien`,
        path: `/app/claim/clients`,
        icon: <Iconify icon={'fluent-mdl2:waitlist-confirm'} width={32} height={32} />,
        isOpen: true,
        isBold: false,
        isCollapse: true,
        children: [
          {
            title: `Eingang`,
            path: `/app/claim/clients/new`,
            info:
              countData.claim?.client?.new || countData.claim?.client?.new === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.client?.new}
                </Label>
              ) : (
                ''
              ),
          },
          {
            title: `Angefordert`,
            path: `/app/claim/clients/requested`,
            info:
              countData.claim?.client?.requested || countData.claim?.client?.requested === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.client?.requested}
                </Label>
              ) : (
                ''
              ),
          },

          {
            title: `Ausgleichzahlung`,
            path: `/app/claim/clients/reclaim`,
            info:
              countData.claim?.client?.reclaim || countData.claim?.client?.reclaim === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.client?.reclaim}
                </Label>
              ) : (
                ''
              ),
          },
          {
            title: `Ausgleichzahlung angefordert`,
            path: `/app/claim/clients/reclaim-requested`,
            info:
              countData.claim?.client['reclaim-requested'] || countData.claim?.client['reclaim-requested'] === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.client['reclaim-requested']}
                </Label>
              ) : (
                ''
              ),
          },
          {
            title: `Ausgleichzahlung gutschreiben`,
            path: `/app/claim/clients/reclaim-payback`,
            info:
              countData.claim?.client['reclaim-payback'] || countData.claim?.client['reclaim-payback'] === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.client['reclaim-payback']}
                </Label>
              ) : (
                ''
              ),
          },
          {
            title: `Inkasso`,
            path: `/app/claim/clients/inkasso`,
            info:
              countData.claim?.client['inkasso'] || countData.claim?.client['inkasso'] === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.client['inkasso']}
                </Label>
              ) : (
                ''
              ),
          },

          {
            title: `Abgeschlossen`,
            path: `/app/claim/clients/completed`,
            info:
              countData.claim?.client?.completed || countData.claim?.client?.completed === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.client?.completed}
                </Label>
              ) : (
                ''
              ),
          },
        ],
      },
      {
        title: `Provisionen`,
        path: `/app/claims/provisions`,
        icon: <Iconify icon={'fluent-mdl2:waitlist-confirm'} width={32} height={32} />,
        isOpen: true,
        isBold: false,
        isCollapse: true,
        children: [
          {
            title: `Eingang`,
            path: `/app/claim/provisions/new`,
            info:
              countData.claim?.provision?.new || countData.claim?.provision?.new === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.provision?.new}
                </Label>
              ) : (
                ''
              ),
          },
          {
            title: `Angefordert`,
            path: `/app/claim/provisions/requested`,
            info:
              countData.claim?.provision?.requested || countData.claim?.provision?.requested === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.provision?.requested}
                </Label>
              ) : (
                ''
              ),
          },
          {
            title: `Zahlung eingegangen`,
            path: `/app/claim/provisions/bankReceived`,
            info:
              countData.claim?.provision?.bankReceived || countData.claim?.provision?.bankReceived === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.provision?.bankReceived}
                </Label>
              ) : (
                ''
              ),
          },

          {
            title: `Abgeschlossen`,
            path: `/app/claim/provisions/completed`,
            info:
              countData.claim?.provision?.completed || countData.claim?.provision?.completed === 0 ? (
                <Label variant="ghost" color="primary">
                  {countData.claim?.provision?.completed}
                </Label>
              ) : (
                ''
              ),
          },
        ],
      },
    ],
  };

  if (isAdmin) {
    return [
      {
        items: mainItems,
      },
      processManagementItems,
      paymentManagementItems,
      claimsManagementItems,
    ];
  }
  //  MANAGER
  if (userRole !== 'admin' && userViewRights.includes('process_management')) {
    return [
      {
        items: mainItems,
      },
      processManagementItems,
    ];
  }

  if (userRole !== 'admin' && !userViewRights.includes('process_management')) {
    return [
      {
        items: mainItems,
      },
    ];
  }

  //  VALIDATOR
  if (userRole === 'validator') {
    return [
      {
        items: [
          {
            title: 'Dashboard',
            path: '/app/dashboard',
            icon: <Iconify icon={'mdi:view-dashboard'} width={32} height={32} />,
          },
          {
            title: `Validierung 2022 ${countData ? `(${countData['2022'].new})` : ''}`,
            path: '/app/process/new/2022',
            icon: <PlagiarismIcon />,
          },
          {
            title: `Validierung 2023 ${countData ? `(${countData['2023'].new})` : ''}`,
            path: '/app/process/new/2023',
            icon: <PlagiarismIcon />,
          },
        ],
      },
    ];
  }

  return null;
};

export default getSideBarConfig;
